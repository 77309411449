var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"footer-props":{ 'itemsPerPageOptions': [50, 100, 200, -1] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\bookings\\needrefund.3723')))]),_c('v-spacer')],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.trip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip ? item.trip.name : '')+" ")]}},{key:"item.organizer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip && item.trip.user ? item.trip.user.firstname : '')+" "+_vm._s(item.trip && item.trip.user ? item.trip.user.lastname : '')+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.firstname : '')+" "+_vm._s(item.user ? item.user.lastname : '')+" ")]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.start,'DD.MM.YY'))+" - "+_vm._s(_vm._f("moment")(item.finish,'DD.MM.YY'))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currency(item.currency))}})]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paid(item))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currency(item.currency))}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.BOOKING_STATUS_TITLE[item.status]))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openRefundModal(item)}}},[_vm._v("mdi-arrow-u-left-top")])]}}],null,true)}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.refundModal),callback:function ($$v) {_vm.refundModal=$$v},expression:"refundModal"}},[_c('v-card',{staticClass:"base-alert-dialog"},[_c('v-card-title',{staticClass:"headline"}),_c('v-card-text',{staticClass:"base-alert-dialog__text"},[_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookings\\needrefund.5105'),"outlined":""},model:{value:(_vm.refundObject.amount),callback:function ($$v) {_vm.$set(_vm.refundObject, "amount", $$v)},expression:"refundObject.amount"}}),_c('v-textarea',{attrs:{"label":_vm.$t('src\\views\\bookings\\needrefund.5646'),"outlined":""},model:{value:(_vm.refundObject.comment),callback:function ($$v) {_vm.$set(_vm.refundObject, "comment", $$v)},expression:"refundObject.comment"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"error"},on:{"click":_vm.refund}},[_vm._v(" "+_vm._s(_vm.$t('src\\views\\bookings\\needrefund.7642'))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"gray"},on:{"click":function($event){_vm.refundModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('src\\views\\bookings\\needrefund.1030'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }